@import "../../../assets/styles/variables.scss";

.rates-report-container {
  .amplify-select.amplify-field-group__control {
    max-width: 240px;
  }

  .advanced-search-container {
    height: calc(90vh - 145px);
    overflow: auto;

    h2 {
      margin: 10px;
      font-size: 18px;
      font-weight: 600;
      color: $light-black;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .inputs-container {
      display: flex;
      padding: 0px 0 10px 30px;

      .custom-autocomplete {
        margin-top: 4px;
      }

      .amplify-input {
        height: 50px;
      }
    }

    .rate-inputs-container {
      display: flex;
      flex-direction: column;
      padding: 0px 0 10px 30px;

      .rate-inputs {
        display: flex;
        align-items: center;

        .amplify-checkboxfield {
          margin-top: 16px;
          margin-left: 12px;
        }
      }
    }
  }

  .advanced-search-submit {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    background-color: $white;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .report-table {
    background-color: $white;

    th {
      text-align: left;
      padding: 10px 5px;
      border-bottom: 3px solid $background-color;
    }
    td {
      padding: 7px;
    }
    tr {
      &:nth-child(even) {
        background-color: $shade-blue !important;
      }
      &:nth-child(odd) {
        background-color: $white !important;
      }
    }
  }
}
