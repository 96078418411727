@import "../../../assets/styles/variables.scss";

.switch-container {
  h1 {
    color: $white;
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .switch-buttons-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 6px 0;

    .amplify-button {
      background-color: $light-gray;
      width: 72px;
      height: 36px;
      font-weight: 600;

      &:hover {
        background-color: $light-gray;
        border-color: $gray;
        color: $blue;
      }
    }

    .active-switch-button {
      background-color: $white;
      color: $blue;

      &:hover,
      &:focus {
        background-color: $white;
        border-color: $gray;
        color: $blue;
        cursor: default;
      }
    }
  }
}

.mobile-switch-container {
  margin-top: 12px;

  h1 {
    font-size: 24px;
    font-weight: 500;
  }

  .switch-buttons-container {
    flex-direction: column;
    margin: 0;

    .amplify-button {
      margin: 6px 0;
      width: 80vw;
    }
  }
}
