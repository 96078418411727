@import "../../../assets/styles/variables.scss";

.tabs-container {
  .amplify-tabs {
    border-color: $gray;
  }

  .amplify-tabs-item {
    color: $black;
    border-color: $gray;

    &:hover {
      color: $black;
    }
  }

  .amplify-tabs-item[data-state="active"] {
    color: $black;
    border-color: $gray;
    background-color: $gray;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
