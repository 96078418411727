@import "./assets/styles/variables.scss";

.minimized-desktop-nav {
  padding-left: 70px;
}

.desktop-nav {
  padding-left: 240px;
  background-color: $background-color;
  min-height: 100vh;
}

.mobile-nav.nav {
  padding-top: 75px;
}

[data-amplify-authenticator] {
  background-color: $black;
  height: 100vh;
  width: 100vw;

  [data-amplify-router] {
    border-radius: $border-radius;
  }

  .login-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amplify-button--primary {
    background-color: $green;
  }

  .amplify-button--small {
    color: $green;
    &:hover {
      background-color: transparent;
    }
  }
}
