@import "../../../assets/styles/variables.scss";

.toggle {
  padding-left: 4px;
  .amplify-switch-label {
    color: $white;
  }

  .amplify-switch-track {
    cursor: pointer;
  }
}

.mobile-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;

  .amplify-switch__wrapper {
    justify-content: space-between;
    width: 80vw;
  }
}
