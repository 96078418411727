@import "../../../assets/styles/variables.scss";

.user-management-container {
  .clickable-table-container
    .clickable-table-with-button
    .label-with-button
    h1 {
    margin: 0 !important;
  }

  .no-data-screen {
    display: none;
  }
}

.user-edit-form-container {
  z-index: 100;
  position: absolute;
  top: 50%;
  left: calc(50% + $navbar-width / 2);
  transform: translate(-50%, -50%);
  width: 50vw;
  height: 75vh;
  border-radius: $border-radius;
  background-color: $off-white;

  .user-edit-headers {
    display: flex;
    justify-content: center;
    align-items: center;

    .close {
      position: absolute;
      top: 0;
      right: 12px;
      color: $black;
      float: right;
      font-size: 28px;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $blue;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  .user-edit-body {
    height: calc(100% - 170px);
    overflow: auto;

    margin: 0 12px;

    .user-edit-form {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .input {
        height: 100px;
      }

      .amplify-input,
      .amplify-select__wrapper {
        width: 340px;
      }
    }

    .edit-only-flows {
      margin-left: 5px;

      h2,
      p {
        margin: 0;
        padding: 0;
      }

      h2 {
        font-size: 16px;
        font-weight: bold;
      }

      p {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 6px;
      }

      .button {
        margin-left: 0;
        .amplify-button {
          width: 180px;
        }
      }
    }
  }

  .user-edit-footers {
    display: flex;
    position: absolute;
    padding: 12px 12px 12px 7px;
    bottom: 0;
    width: 100%;
    justify-content: flex-end;

    .button {
      .amplify-button {
        width: auto;
      }
    }
  }
}

.mobile-user-management-container {
  .user-edit-form-container {
    width: 100vw;
    height: calc(100vh - $mobile-navbar-width);
    top: $mobile-navbar-width;
    left: 0;
    transform: none;
    border-radius: 0;
  }
}
