@import "../../../assets/styles/variables.scss";

.vehicle-activity-report-description {
  .report-table {
    background-color: $white;

    th {
      text-align: left;
      padding: 10px 5px;
      border-bottom: 3px solid $background-color;
    }
    td {
      padding: 7px;
    }
    tr {
      &:nth-child(even) {
        background-color: $shade-blue !important;
      }
      &:nth-child(odd) {
        background-color: $white !important;
      }
    }
  }
}
