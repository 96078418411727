@import "../../assets/styles/variables.scss";

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 990;
  display: flex;
  align-items: center;
  justify-content: center;

  .amplify-loader {
    height: 90px;
    z-index: 1000;
  }
}

.mobile-loader {
  left: 0;
}
