body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", */
  /* "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
  min-height: 50vh;

  h1,
  h2,
  h3,
  h4 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
  }

  /* Custom Scrollbar */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #888;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  /* Scrollbar corner */
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
