@import "../../../assets/styles/variables.scss";

// Handles search/date inputs
.input {
  .amplify-select__wrapper {
    background-color: white;
    border-color: $gray;
    height: 44px;
    border-radius: 4px;
  }

  .amplify-selectfield {
    margin: 2px 5px 6px;
  }

  .amplify-select.amplify-field-group__control {
    height: 50px;
    padding: 12px 40px 12px 10px;
    font-size: 15px;
  }
}

.mobile-input {
  .amplify-selectfield {
    margin: 2px 5px;
  }
}
