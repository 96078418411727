@import "../../../assets/styles/variables.scss";

.vehicle-communications-report-description {
  .report-table {
    background-color: $white;

    th {
      text-align: left;
      padding: 10px 5px;
      border-bottom: 3px solid $background-color;
    }
    td {
      padding: 7px;
    }
    tr {
      &:nth-child(even) {
        background-color: $shade-blue !important;
      }
      &:nth-child(odd) {
        background-color: $white !important;
      }
    }
  }
}

.advanced-search-submit {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
  background-color: $white;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
