@import "../../../assets/styles/variables.scss";

.measurement-modal-container {
  position: fixed;
  top: 192px;
  left: 250px;
  z-index: 50;
  background-color: $white;
  border-radius: 4px !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  padding: 12px;

  .measurement-modal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 180px;

    h1 {
      margin: 0;
      margin-bottom: 6px;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-size: 14px;
    }

    .measurement-modal-footers {
      margin-top: 12px;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
