@import "../../../assets/styles/variables.scss";

.vehicles-page-container {
  width: -webkit-fill-available;

  .edit-vehicle-container {
    z-index: 100;
    background-color: $off-white;
    height: 92vh;
    width: 65vw;
    position: fixed;
    top: 50%;
    left: calc(50% + 105px);
    transform: translateY(-50%) translateX(-50%);
    border-radius: 4px;
    padding-left: 6px;
    box-shadow: $box-shadow;
    z-index: 999;
    overflow: auto;

    .edit-form-headers {
      display: flex;
      justify-content: center;
      align-items: center;

      .x-container {
        position: absolute;
        cursor: pointer;
        font-size: 30px;
        font-weight: bold;
        right: 25px;
        top: 0;
        color: $dark-black;
        &:hover {
          color: $blue;
        }
      }

      h1 {
        margin-bottom: 12px;
        padding: 0;
      }
    }

    .amplify-text {
      color: $dark-black;
      font-weight: 500;
    }

    .edit-form-inputs {
      display: flex;
      flex-wrap: wrap;
    }

    .edit-forms {
      margin: 0;
      overflow: auto;
      height: calc(95vh - 170px);
    }

    .messages-container {
      margin-left: 5px;
      .checkbox-container {
        margin-left: 6px;
        margin-bottom: 16px;
      }
    }

    .services-container {
      margin-left: 5px;

      .checkbox-form-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 18px;
        margin-bottom: 12px;
      }
    }

    .status-container,
    .test-message-container,
    .historical-data-container {
      margin-left: 5px;
      margin-bottom: 12px;

      .amplify-textfield,
      .button {
        margin-left: 0 !important;
      }
    }

    .status-container {
      .status-inputs {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 6px;

        .amplify-text {
          font-style: normal;
          margin: 0;
          font-weight: 500;
        }

        .amplify-input {
          width: 175px;
        }
        .amplify-button {
          margin-bottom: 6px;
        }
      }
      .deactivation-date {
        margin: 10px 0 -5px 0;
      }
      .deactivation-date-label {
        font-size: 16px;
        font-weight: 500;
        color: $dark-black;
        font-style: normal;
      }
    }

    .messages-container,
    .services-container,
    .status-container,
    .test-message-container,
    .historical-data-container {
      h2 {
        font-size: 18px;
        margin: 1px 0;
        color: $dark-black;
        font-weight: 500;
      }
      p {
        margin-top: 0;
        margin-bottom: 2px;
        font-size: 12px;
        font-weight: 300;
        font-style: italic;
      }
      .amplify-button {
        width: 175px;
        margin: 7px 0 15px 0;
      }
    }

    .historical-data-container .table-container {
      .table {
        height: auto;
        margin: 0;
        margin-right: 12px;
      }
      .amplify-table__row {
        height: auto;
      }
      .amplify-table__head th p {
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        margin: 0;
      }
      .amplify-table__td {
        white-space: break-spaces;
        height: auto;
      }
    }

    .button-container {
      display: flex;
      position: absolute;
      bottom: 0 !important;
      right: 0 !important;
      width: 100%;
      height: 60px;
      background-color: $off-white;

      .amplify-button {
        position: absolute;
        top: 6px;
        right: 6px !important;
        width: auto !important;
        width: 80px !important;
      }
    }

    .amplify-input,
    .amplify-select__wrapper {
      width: 260px;
    }

    .save-button .amplify-button {
      width: 140px !important;
    }
  }

  .mobile-edit-vehicle-container {
    margin: 0;
    z-index: 100;
    background-color: $off-white;
    height: calc(100vh - 75px);
    width: 100vw;
    top: 75px;
    left: auto;
    transform: none;
    border-radius: 0;
    padding-left: 6px;
    box-shadow: $box-shadow;

    .edit-form-headers {
      .x-container {
        display: none;
      }

      h1 {
        font-size: 24px;
        padding-right: 0;
      }
    }

    .edit-forms {
      display: flex;
      width: 100vw;
      flex-wrap: wrap;
      margin: 0;
      overflow: auto;
      height: calc(100vh - 254px);

      span {
        .mobile-input {
          width: 50vw;
        }
      }
    }

    .checkbox-container {
      width: 43.5vw;
    }
    .amplify-checkbox {
      align-items: flex-start;
    }

    .amplify-input,
    .amplify-select__wrapper {
      width: 95%;
    }

    // Landscape Mobile
    @media (min-width: 650px) {
      .checkbox-container {
        width: 47vw;
      }
    }

    .button-container {
      flex-direction: column;
      align-items: center;
      left: 50%;
      transform: translateX(-50%);

      .close-button {
        margin-bottom: 6px;
      }

      .close-button,
      .save-button {
        justify-content: center;

        .amplify-button {
          width: 90vw;
        }

        .button {
          margin-left: 0;
        }
      }
    }
  }

  .advanced-search-container {
    height: calc(90vh - 145px);
    overflow: auto;

    .second-row-inputs {
      padding-top: 0 !important;
    }

    .amplify-selectfield {
      min-width: 180px;
    }
  }

  .page-container {
    height: auto;
  }
}
