@import "../../assets/styles/variables.scss";

.map-container {
  overflow: hidden;

  // Handles overwrite for the map geofence controls
  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right,
  .mapboxgl-ctrl-top-left,
  .mapboxgl-ctrl-top-right,
  .maplibregl-ctrl-bottom-left,
  .maplibregl-ctrl-bottom-right,
  .maplibregl-ctrl-top-left,
  .maplibregl-ctrl-top-right {
    position: relative;
  }

  .mapboxgl-ctrl-group {
    position: fixed !important;
    top: 100px;
    margin-left: 12px !important;
    background-color: $white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-rotate {
    display: none;
  }

  .toggle-container {
    position: fixed;
    bottom: 12px;
    margin-left: 12px;
    background-color: $white;
    padding: 6px;
    border-radius: 4px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 260px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);

    .toggles {
      margin-top: 4px;
    }

    .toggle .amplify-switch-label,
    h1,
    p {
      color: black;
      font-size: 14px;
    }

    h1,
    p {
      margin: 0;
      font-weight: bold;
    }

    p {
      margin-left: 4px;
    }

    .switch-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 6px;
      width: 248px;
    }

    .switch-container .switch-buttons-container .amplify-button {
      border: 1px solid black;
    }

    .x-container {
      color: black;
      font-weight: 500;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      right: 6px;
      top: 0;
      font-size: 30px;
      font-size: 30px;
      margin: 0;
      &:hover {
        cursor: pointer;
        color: $blue;
      }
    }
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-circle {
    background-image: url("../../assets/icons/circle-outline.png");
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    display: block !important;
    top: 100px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none !important;
    border-bottom: 1px solid #ddd;
  }

  .mapbox-gl-draw_polygon {
    margin-top: 28px;
  }

  .mapbox-gl-draw_line {
    position: fixed;
    top: 124px !important;
    background-color: $white;
    border-radius: 4px !important;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #f2f2f2 !important;
    }
  }

  .mapbox-gl-draw_point {
    background-image: url("../../assets/icons/generate-polygon.png");
    background-size: 98%;
    background-repeat: no-repeat;
    z-index: 10;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-rotate {
    background-image: url("../../assets/icons/rotate-icon-btn.png");
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-scale {
    background-image: url("../../assets/icons/scale-icon-btn.png");
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
  }

  .map-controls-container {
    position: fixed;
    bottom: 12px;
    margin-left: 12px;
    background-color: $white;
    border-radius: 4px !important;
    display: flex;
    justify-content: center;
    color: black;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    height: 44px;

    p {
      margin: 8px 12px;
      font-weight: 700;
    }

    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.desktop-map-container {
  .filter-container {
    background: none !important;
    box-shadow: none;
    height: 54px;

    .filter-inputs-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .filter-with-button-container {
      align-items: flex-start;
      padding-top: 6px;
    }

    .amplify-textfield {
      display: flex;
      flex-direction: column-reverse;
    }

    .submit-button {
      padding-top: 2px;
    }
  }

  .maplibregl-ctrl-geocoder,
  .maplibregl-ctrl-geocoder .suggestions {
    box-shadow: none;
    margin: 23px 0 0 0 !important;
  }

  .maplibregl-ctrl-top-left {
    position: absolute;
    z-index: 11 !important;
    top: 5px;
    left: 134px;
    box-shadow: none;
    height: 40px !important;

    .maplibregl-ctrl-geocoder--input {
      background-color: white;
      padding: 7px 14px;
      font-family: $text-font;
      font-size: 16px;
      width: 64vw;
      margin-top: 1px;

      &:focus {
        outline: none;
      }
    }

    .maplibregl-ctrl-geocoder--icon-loading {
      display: none !important;
    }

    .suggestions {
      width: 67vw !important;
      margin-top: 0px !important;
      border: 1px solid $gray;
    }

    svg {
      display: none;
    }
  }
}

.mobile-map-container {
  height: calc(100dvh - 75px);

  .minimized-mobile-filter-container {
    width: 50vw;
  }

  .mapboxgl-ctrl-group,
  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-rotate,
  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-circle {
    display: none !important;
  }

  .mobile-map-controls-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 50vw;
    top: 75px;
    right: 0;
    background-color: rgba(46, 49, 50, 0.95) !important;
    border: 1px solid $gray;
    border-left: none;

    p {
      color: $white;
      font-weight: 500;
    }
  }

  .filter-container {
    z-index: 99;
    left: 0;
  }

  .toggle-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    top: 135px;
    box-shadow: none;
    padding: 6px 12px;
    background-color: rgba(46, 49, 50, 0.95) !important;
    margin-left: 0;
    border-radius: 0 !important;
    height: $mobile-full-height;
    min-height: 90vh;
    z-index: 100;

    .toggle .amplify-switch-label,
    h1,
    p {
      color: white;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      text-align: center;
    }

    .mobile-map-controls {
      height: auto;
      overflow: auto;
    }

    .mobile-close-button {
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      font-size: 14px;
      color: $white;
      font-weight: 500;
    }

    .switch-container {
      width: auto;
    }
  }

  .lookup-modal-container .lookup-modal {
    height: auto;
  }

  .mobile-page-container {
    .search-form-container {
      height: calc(100vh - 140px) !important;
    }
  }

  .location-search-input {
    position: absolute;
    top: auto !important;
    bottom: 94px;
    left: 300px;
    z-index: 98 !important;
  }

  .maplibregl-ctrl-bottom-left {
    position: fixed;
    z-index: 98 !important;

    .maplibregl-ctrl {
      width: 60vw !important;
    }

    .maplibregl-ctrl-geocoder--input {
      height: 44px;
      border-radius: 4px;
      border: 1px solid $gray;
      padding-left: 12px;
      width: 320px;
      background-color: white;
    }

    .maplibregl-ctrl-geocoder--icon-loading {
      display: none !important;
    }

    .suggestions {
      width: 320px !important;
    }

    svg {
      display: none;
    }
  }

  // Landscape Mobile
  @media (min-width: 650px) {
    .toggle-container {
      .mobile-map-controls {
        flex-direction: row;
        display: flex;
        width: 100vw;
        justify-content: space-evenly;
        align-items: center;
      }

      .switch-container {
        width: 30vw;
        .amplify-button {
          width: 30vw;
        }
      }

      .toggles {
        width: 24vw;
        .amplify-switch__wrapper {
          justify-content: flex-start;
        }
      }

      .mobile-close-button {
        display: none;
      }
    }
  }
}

.editing-map-view {
  .mapboxgl-ctrl-group {
    position: fixed !important;
    margin-left: 12px !important;
    top: 160px;
    background-color: $white;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-rotate {
    display: block !important;
    top: 100px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid rgba(100, 100, 100, 0);
  }

  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-scale {
    display: block !important;
    top: 130px;
    border-radius: 0;
    border-bottom: 1px solid rgba(100, 100, 100, 0);
  }

  .mapbox-gl-draw_line,
  .mapbox-gl-draw_polygon,
  .mapbox-gl-draw_point,
  .mapboxgl-ctrl.mapboxgl-ctrl-group.custom-ctrl-circle {
    display: none !important;
  }
}

.hidden-flyout {
  opacity: 0;
}

// Map Search Bar
.min-nav-map-filter-container {
  width: calc(100vw - 70px) !important;
}

.map-filter-container {
  height: 92px;
  background-color: $white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 23px 20px 20px 12px;
  display: flex;
  position: sticky;
  z-index: 10 !important;
  left: 0;
  width: calc(100vw - $navbar-width);

  .map-filter-box {
    display: inline;
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 3px;
    width: 100%;
  }

  .map-filter-dropdown {
    display: inline;
    background-color: $blue;
    color: $white;
    border: none;
    border-radius: 5px;
    padding: 4px 6px;
    height: 100%;
    font-size: 16px;
    border: 4px solid $blue; // Makes the chevron in the select move over to the left some

    &:focus-visible {
      outline: none;
    }

    option {
      background-color: $white;
      color: $black;
      font-size: 15px;
      font-family: $text-font;

      &:hover {
        color: white;
      }
    }
  }

  .amplify-field-group--has-inner-start .amplify-input {
    border: none;
    padding-left: 4px;
    padding-top: 6px;
  }
  .amplify-field-group__inner-start {
    display: none;
  }
  .amplify-input:focus {
    box-shadow: none;
  }

  .map-search-input {
    border: none;
    border-radius: 5px;
    padding: 3px 0 0;
    position: relative;
    margin-top: -41px;
    margin-left: 120px;

    &:focus-visible {
      outline: none;
    }
  }
  .map-search-icon {
    display: contents;
    position: relative;
    cursor: pointer;
    z-index: 300;
    background: none;
    border: none;

    .icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 18px;
      max-width: 20px;
    }
  }

  .map-search-button {
    background-color: $blue;
    color: $white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  }
}

.date-filter {
  margin: 0 10px;
  font-size: 16px;
  input {
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 3px;
    height: 100%;
  }
}

.search-button {
  .button {
    button {
      height: 48px;
    }
  }
}

.geofence-map-lookup {
  position: absolute;
  bottom: 1px;
  left: 120px;
  width: 100% !important;

  .maplibregl-ctrl-geocoder--icon {
    display: none;
  }

  .maplibregl-ctrl-geocoder {
    max-width: 90%;
  }
  .maplibregl-ctrl-geocoder {
    box-shadow: none;
  }

  #Global_Icon {
    display: none;
    position: absolute;
    top: 8px;
    left: -5px;
    z-index: 100;
  }
}

.maplibregl-ctrl-geocoder {
  .suggestions {
    li {
      font-size: 15px;
      a:hover {
        background-color: $blue !important;
        color: $white !important;
      }
    }
  }
}

.amplify-autocomplete__menu__options {
  li {
    font-size: 15px;
    &:hover {
      background-color: $blue !important;
      color: $white !important;
    }
  }
}

.amplify-autocomplete__menu__option--active {
  background-color: $blue !important;
  color: $white !important;
}
