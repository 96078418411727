@import "../../../assets/styles/variables.scss";

.edit-service-container {
  z-index: 100;
  background-color: $off-white;
  height: 85vh;
  width: 55vw;
  position: fixed;
  top: 50%;
  left: calc(50% + 105px);
  transform: translateY(-50%) translateX(-50%);
  border-radius: 4px;
  padding-left: 6px;
  box-shadow: $box-shadow;
  z-index: 999;

  .edit-form-headers {
    display: flex;
    justify-content: center;
    align-items: center;

    .x-container {
      position: absolute;
      cursor: pointer;
      font-size: 30px;
      font-weight: bold;
      right: 25px;
      top: 0;
      color: $dark-black;
      &:hover {
        color: $blue;
      }
    }

    h1 {
      margin-bottom: 12px;
      padding: 0;
    }
  }

  .amplify-text {
    color: $dark-black;
    font-weight: 500;
  }

  .edit-form-inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .top-inputs {
      display: flex;
    }
  }

  .location-types-container {
    margin-left: 5px;

    .checkbox-form-container {
      margin-right: 12px;
      height: 140px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      overflow-x: auto;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 2px;
      color: $dark-black;
      font-weight: 500;
    }
    p {
      margin-top: 0;
      font-size: 12px;
      font-weight: 300;
      font-style: italic;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 12px;
    right: 12px;
  }

  .amplify-input,
  .amplify-select__wrapper {
    width: 260px;
  }
}

.mobile-edit-service-container {
  margin: 0;
  z-index: 100;
  background-color: $off-white;
  height: calc(100vh - 75px);
  width: 100vw;
  top: 75px;
  left: auto;
  transform: none;
  border-radius: 0;
  padding-left: 6px;
  box-shadow: $box-shadow;

  .edit-form-headers {
    .x-container {
      display: none;
    }

    h1 {
      font-size: 24px;
      padding-right: 0;
    }
  }

  .edit-forms {
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    margin: 0;
    overflow: auto;

    span {
      .mobile-input {
        width: 50vw;
      }
    }
  }

  .checkbox-container {
    width: 43.5vw;
  }
  .amplify-checkbox {
    align-items: flex-start;
  }

  .amplify-input,
  .amplify-select__wrapper {
    width: 95%;
  }

  // Landscape Mobile
  @media (min-width: 650px) {
    .checkbox-container {
      width: 47vw;
    }
  }

  .button-container {
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);

    .close-button {
      margin-bottom: 6px;
    }

    .close-button,
    .save-button {
      justify-content: center;

      .amplify-button {
        width: 90vw;
      }

      .button {
        margin-left: 0;
      }
    }
  }
}
