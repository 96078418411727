@import "../../../assets/styles/variables.scss";

// Handles search input
.search-input {
  .amplify-input {
    background-color: white;
    // border-color: $gray;
    border: none;
    height: 44px;
    width: 220px;
  }

  .amplify-textfield {
    margin: 2px 5px 6px;
  }

  // Handles the input labels
  .amplify-text,
  .amplify-label {
    color: $white;
    margin: 0;
  }
}

.mobile-search-input {
  .amplify-input {
    width: 100%;
    border: none;
  }
}

.mobile-search-input {
  .amplify-textfield {
    margin: 2px 5px;
  }
}

.amplify-field-group__inner-end {
  display: none !important;
}

.amplify-field-group--has-inner-end .amplify-input {
  padding-inline-end: 0 !important;
}

.amplify-searchfield__search {
  border: none !important;
}

.amplify-searchfield__search:hover {
  color: $blue !important;
  background-color: $white !important;
  border-color: $blue !important;
}
.amplify-icon {
  svg {
    transform: scale(1.2) !important;
  }
}
