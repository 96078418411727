@import "../../../assets/styles/variables.scss";

.co-modal {
  .co-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 900;
  }

  .co-modal-container {
    position: absolute;
    top: 50%;
    left: calc(50% + 120px);
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 90vh !important;
    width: 65vw;
    background-color: $white;
    z-index: 990;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    border: none;

    .co-modal-header {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 6px;

      .co-modal-title {
        font-size: 28px;
        font-weight: 600;
        color: $black;
        padding: 12px;
        margin: 0;
      }

      .x-container {
        position: absolute;
        right: 18px;
        top: 12px;
        cursor: pointer;
        font-size: 16px;
        width: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: $blue;
        }
      }
    }

    .co-modal-content {
      // Using h2 for the titles inside modal content
      h2 {
        padding: 0 12px;
      }
    }
  }
}
