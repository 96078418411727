@import "../../assets/styles/variables.scss";

// TRIPS
.trips-container {
  display: flex;
  flex-direction: column;
  margin: 6px;
  white-space: pre;

  .trips-header {
    color: $black;
    margin-bottom: 12px;

    .x-container {
      cursor: pointer;
      position: absolute;
      right: 5px;
      bottom: -5px;
      font-size: 20px;
      font-weight: 500;
      margin: 0 5px;
      width: 14px;
      &:hover {
        color: $blue;
      }
    }

    .amplify-heading {
      word-wrap: break-word;
      font-size: 22px;
      font-weight: 500;
    }

    .refresh-container {
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      position: relative;

      &:hover {
        color: $blue;
      }

      svg {
        position: absolute;
        top: -15px;
        right: 30px;
        font-size: 20px;
        min-width: 18px;
      }
    }
  }
  .trips-controls {
    position: absolute;
    right: 0;
    bottom: 10px;
  }

  .trip-cards-container {
    overflow: auto;
    max-height: calc(100vh - 154px);
  }

  h1 {
    margin: 10px 0 3px 0;
    color: $blue;
    font-weight: 600;
    font-family: $headers-font;
    word-wrap: break-word;
  }
}

// Empty Trips Container
.empty-trips-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;
  margin-bottom: 6px;

  p {
    color: $light-black;
    font-size: 1rem;
    margin: 0;
    padding: 6px;
  }

  .closest-reported-date {
    color: $blue;
    text-decoration: underline;
    cursor: pointer;
  }
}

// TRIP CARD
.trip-card {
  border: 2px solid $gray;
  border-radius: $border-radius;
  // min-height: 95px;
  margin: 12px 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  background-color: $white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  h2 {
    color: $black;
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    color: $black;
    font-size: 18px;
    font-weight: 600;
    margin: 5px 0;
  }
  .icon {
    font-size: 16px;
    margin-right: 5px;
    color: $blue;
  }
  .trip-date {
    color: $light-black;
    font-size: 16px;
    margin: 3px 0 0;
    padding-top: 4px;
  }
  .trip-distance {
    color: $light-black;
    font-size: 16px;
    margin: 3px 0 0;
    padding-top: 4px;
  }

  &:hover {
    background-color: $shade-blue;
  }

  // First child should not have margin top
  &:first-child {
    margin-top: 0;
  }
  //Last child should not have margin bottom
  &:last-child {
    margin-bottom: 6px;
  }
}

// CUSTOM RANGE TRIP CARD
.custom-range-trip-card {
  height: 100%;
  cursor: default;
  border: 2px solid $gray;
  border-radius: $border-radius;
  margin: 12px 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  background-color: $white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  h2 {
    margin-bottom: 6px;
  }

  .custom-range-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .custom-range-time-inputs-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 0;

      .amplify-label {
        text-align: left;
      }

      .amplify-textfield {
        width: 100%;
        margin: 0 10px 6px 0;
      }
    }

    .customRangeTrip-btn {
      color: $white;
      font-size: 16px;
      background-color: $blue;
      border: none;
      border-radius: 5px;
      width: 25px;
      height: 27px;
      margin: 40px 0 4px 10px;
      cursor: pointer;
      svg {
        width: 7px;
      }
    }
  }
}

// Selected Trip Card
.selected-trip-card {
  border-color: $blue;
  background-color: $shade-blue;

  &:hover {
    background-color: $shade-blue;
  }
}

.position-relative {
  position: relative;
}

.go-icon {
  position: absolute;
  right: 0;
  color: $white;
  background-color: $blue;
  padding: 3px 9px;
  border-radius: 5px;
}
