@import "../../assets/styles/variables.scss";

.mapboxgl-marker {
  cursor: pointer;

  .map-marker {
    svg {
      stroke: black;
      stroke-width: 24px;
      stroke-linejoin: round;
    }
  }
}

.mapboxgl-popup-content {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  height: 320px;
  width: 400px;
  padding-top: 8px !important;
  overflow: hidden;

  .mapboxgl-popup-close-button.maplibregl-popup-close-button {
    color: $blue;
    font-size: 24px;
    position: fixed;
    font-weight: 500;
    &:hover {
      background: none;
    }
    &:focus-visible {
      outline: none;
    }
  }

  .popup-headers {
    text-align: center;

    .drag-icon {
      max-width: 16px;
      position: absolute;
      left: 6px;
      top: 7px;
      cursor: move;
      color: $blue;
    }

    h1 {
      font-size: 18px;
      margin: -3px 0 8px;
    }

    p {
      margin: 0;
      color: $blue;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    margin-bottom: 8px;
  }

  .popup-grid-container {
    overflow-y: scroll;
    height: 230px;
    margin-bottom: 6px;
    border-top: 1px solid $amplify-input-border;
    border-bottom: 1px solid $amplify-input-border;

    .grid-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: 1px solid $amplify-input-border;
      width: 380px;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: none;
      }

      &:nth-child(odd) {
        background-color: #eaeaea;
      }

      .left-column {
        border-right: 1px solid $amplify-input-border;
        width: 174px;
      }

      .right-column {
        width: 200px;
      }

      p {
        font-size: 14px;
        margin: 0;
        padding: 2px 0 2px 2px;
      }
    }
  }

  .popup-buttons {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    margin: 0;

    .button {
      margin: 0;
    }

    .amplify-button {
      width: 140px;
      font-size: 12px;
      padding: 0;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
