@import "../../../assets/styles/variables.scss";

.advanced-search-container {
  height: calc(90vh - 145px);
  overflow: auto;

  .second-row-inputs {
    padding-top: 0 !important;
  }

  .amplify-selectfield {
    min-width: 180px;
  }
}

.co-modal-container {
  .clickable-table-container {
    .label-with-button {
      display: none !important;
    }
  }
}
