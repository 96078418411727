@import "../../../assets/styles/variables.scss";

.button {
  margin-left: 5px;

  .amplify-button--disabled,
  button.disabled-button {
    color: $gray;
    border-color: $gray;
    cursor: default;
    &:hover {
      color: $gray;
      border-color: $gray;
      cursor: default;
      background-color: white;
    }
  }

  .amplify-button {
    background-color: white;
    border-color: $gray;
    color: $blue;
    font-size: 16px;
    height: 44px;
    width: 90px;
    padding: 6px;
    &:hover {
      color: $blue;
      background-color: #f2f2f2;
      border-color: $gray;
    }
  }

  .amplify-button--loading {
    background-color: white !important;
    border-color: $gray !important;
  }
}
