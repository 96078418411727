@import "../../../assets/styles/variables.scss";

.report-filters {
  .filter-container {
    // position: fixed;
    width: 100%;
    z-index: 99;
    background-color: $white;
    padding-right: 12px;
    right: 0;
    top: 0px;
    height: 92px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    // padding: 13px 20px 20px 20px;
    padding: 20px 20px 13px;

    .filter-with-button-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .filter-inputs-container {
      width: 67vw;
      display: flex;
      justify-content: flex-end;
    }

    .advanced-search-button,
    .submit-button {
      padding-top: 2px;
    }

    .advanced-search-button {
      .amplify-button {
        width: 100px;
        margin-left: 4px;
      }
    }

    .button {
      height: 49px;
    }

    .input {
      position: relative;
    }

    .amplify-text {
      color: $black;
      position: absolute;
      z-index: 1;
      left: 10px;
      top: -15px;
    }
  }

  .minimized-mobile-filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100vw;
    top: 75px;
    box-shadow: none;
    border: 1px solid $gray;

    p {
      color: $white;
      font-weight: 500;
    }
  }

  .mobile-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: $mobile-full-height;
    min-height: 90vh;
    width: 100vw;
    top: 135px;
    box-shadow: none;
    padding: 6px 12px;

    p {
      color: $white;
    }

    .filter-with-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      height: 70vh;

      .filter-inputs-container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        width: auto;

        .amplify-flex.amplify-field-group.amplify-field-group--horizontal {
          width: 60vw;
        }

        .amplify-searchfield .amplify-input {
          width: 100%;
        }
      }

      .advanced-search-button,
      .submit-button {
        padding-top: 34px;
        .amplify-button {
          width: 60vw;
        }
      }
    }

    .mobile-close-button {
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      font-size: 14px;
      font-weight: 500;
    }

    // Landscape Mobile
    @media (min-width: 650px) {
      .filter-with-button-container {
        .filter-inputs-container {
          justify-content: center;
          flex-direction: row;
          flex-wrap: wrap;
          overflow: auto;
          height: 32vh;

          .amplify-flex.amplify-field-group.amplify-field-group--horizontal {
            width: 80vw;
          }
        }

        .submit-button {
          padding-top: 0;
          position: fixed;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, -50%);
          margin: 0 auto;
        }
      }

      .mobile-close-button {
        display: none;
      }
    }
  }

  .date-pickers {
    display: flex;
  }

  .input .amplify-input {
    height: 50px !important;
  }
  .button .amplify-button {
    height: 50px !important;
  }
}
