@import "../../../assets/styles/variables.scss";

.generate-polygon-modal-container {
  .co-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 900;
  }

  .generate-polygon-modal {
    position: absolute;
    top: 50%;
    left: 57%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 420px;
    width: 440px;
    background-color: $white;
    z-index: 990;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .amplify-text {
      color: $black;
    }

    .generate-polygon-modal-headers {
      text-align: center;
      display: flex;
      justify-content: space-between;
      padding: 0 4px;

      .x-container {
        cursor: pointer;
        font-size: 30px;
        font-weight: 500;
        color: black;
        margin-top: -4px;
        &:hover {
          color: $blue;
        }
      }

      h1 {
        margin: 0;
        text-align: center;
        color: black;
        font-size: 22px;
        font-weight: 500;
      }
    }

    .generate-polygon-modal-inputs {
      span {
        display: flex;
        align-items: flex-end;
        margin-bottom: 6px;
        p {
          margin: 0;
          font-size: 18px;
        }
      }
    }

    .generate-polygon-modal-footers {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
