@import "../../../assets/styles/variables.scss";

.text-area-input {
  margin: 2px 5px 6px;

  .amplify-text {
    color: $dark-black;
  }

  .amplify-textarea {
    background-color: white;
    border-color: $gray;
    width: 530px;
  }
}

.mobile-input {
  .amplify-textarea {
    width: 370px;
  }
}
