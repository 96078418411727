@import "../../assets/styles/variables.scss";

.rates-management-container {
  padding: 6px 12px;

  .schedule-card-container {
    border-radius: 4px;
    border: 1px solid $light-black;
    margin: 12px 0;
    background-color: $gray;

    .schedule-details {
      background-color: black;
      padding: 6px;
      display: flex;
      font-weight: 500;
      justify-content: space-between;

      p {
        margin: 0;
        color: white;
        width: 160px;
        text-align: left;
        font-size: 16px;
      }

      .schedule-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60px;

        span {
          cursor: pointer;
          height: 24px;
          &:hover {
            .delete-icon {
              stroke: red;
            }
          }
        }
      }
    }

    .exclusive-dates {
      background-color: black;
      padding: 0 6px 8px 6px;

      h2 {
        margin: 0;
        color: white;
        font-size: 16px;
      }

      .exclusive-dates-list {
        column-count: 6;

        p {
          margin: 0;
          color: white;
          font-size: 16px;
        }
      }
    }

    table {
      margin-top: -4px;
      padding: 4px;
      text-align: center;

      .amplify-table__th,
      .amplify-table__td {
        margin: 0;
        padding: 2px;
        border-style: none;
      }
    }
  }

  .schedule-card-container:first-child {
    margin-top: 0;
  }

  .schedule-card-container:last-child {
    margin-bottom: 0;
  }

  .schedule-form-container {
    border-radius: 4px;
    border: 1px solid $light-black;
    background-color: $gray;

    .schedule-inputs {
      display: flex;
      flex-wrap: wrap;
      padding: 6px;

      .amplify-select__wrapper,
      .amplify-input,
      .time-input {
        width: 180px;
      }

      .time-input {
        margin-right: 10px;
      }
    }

    .amplify-text {
      color: $dark-black;
    }

    .exclusive-dates-form {
      padding-left: 6px;

      .date-input {
        margin: 6px 0 12px;
        .amplify-input {
          width: 180px;
        }

        p.amplify-text {
          margin-top: -20px !important;
        }
      }

      .add-date-button {
        margin: 14px 0 12px;
        display: flex;
        align-items: flex-end;

        .amplify-button {
          width: 180px;
          border: 1px solid $amplify-input-border;
        }
      }

      .exclusive-date-list-form-container {
        column-count: 6;

        .exclusive-date-list-form {
          display: flex;
          flex-wrap: wrap;
          padding: 3px 2px 3px 6px;
          align-items: center;

          p {
            margin: 0;
            color: $dark-black;
            font-size: 16px;
            margin-right: 4px;
          }

          span {
            cursor: pointer;
            transform: scale(0.7);
            padding-top: 4px;

            .delete-icon {
              stroke: red;
            }
          }
        }
      }
    }

    .charge-form-container {
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      border: 1px solid $light-black;
      background-color: rgba(255, 255, 255, 0.3);
      margin: 8px 6px;
      padding: 6px 0;

      .charges-inputs {
        display: flex;
        flex-wrap: wrap;

        .amplify-select,
        .amplify-input {
          width: 155px;
        }
      }

      span {
        cursor: pointer;
        height: 24px;
        padding-right: 12px;
        .delete-icon {
          stroke: red;
        }
      }
    }

    .schedule-button-container {
      display: flex;
      justify-content: flex-end;
      padding: 6px;
      position: inherit;

      .amplify-button {
        width: 120px;
      }
    }
  }
}

.distance-rate-entry-container {
  padding: 12px;
  margin: 6px;
  box-shadow: $box-shadow;
  border-radius: $border-radius;

  h1 {
    margin: 6px 0;
    font-size: 22px;
  }

  .distance-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    position: inherit;

    .amplify-button {
      width: 140px;
    }
  }
}

.mobile-rates-management-container {
  width: 640px;
}
