@import "../../assets/styles/variables.scss";

.flyout-container {
  position: fixed;
  right: 0;
  top: 92px;
  min-width: 380px;
  z-index: 10 !important;
  height: 100%;
  background-color: $flyout-background-color;
}

.mobile-flyout-container {
  background-color: $flyout-background-color;
  width: 100vw;
  height: calc(100dvh - 75px);
  top: 75px;
  opacity: 1;
}
