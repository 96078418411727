@import "../../../assets/styles/variables.scss";

.checkbox-container {
  padding-top: 2px;
  margin: 0 5px;
  color: $black;
  font-size: 30px;
  margin: 12px 0 6px;

  .amplify-checkbox {
    margin: 4px 0;
  }

  .amplify-checkbox__label {
    font-size: 16px;
    color: $black;
  }

  .amplify-checkbox__icon {
    background-color: $blue;
  }

  .amplify-checkbox__button {
    background-color: $white;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
  }
}
