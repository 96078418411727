@import "../styles/variables.scss";

.logo {
  display: flex;
  align-items: center;
  margin-left: -10px;

  img {
    height: 100px;
  }

  h1 {
    font-size: 34px;
    color: $white;
    font-weight: 500;
    font-family: sans-serif;
  }
}
