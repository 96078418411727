@import "../../../assets/styles/variables.scss";

.edit-form-container {
  margin: 0 12px;

  .edit-form-headers {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      padding-right: 120px;
    }
  }

  .amplify-text {
    color: $black;
  }

  .edit-form-inputs {
    display: flex;
    flex-wrap: wrap;
  }

  .save-button {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 12px;
    right: 12px;
  }

  .amplify-input,
  .amplify-select__wrapper {
    width: 260px;
  }
}

.mobile-edit-form-container {
  margin: 0;

  .edit-form-headers {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;

    h1 {
      font-size: 24px;
      padding-right: 0;
    }
  }

  .edit-forms {
    height: calc(100dvh - 222px);
    display: flex;
    width: 100vw;
    flex-wrap: wrap;
    margin: 0;
    overflow: auto;

    span {
      .mobile-input {
        width: 50vw;
      }
    }
  }

  .checkbox-container {
    width: 43.5vw;
  }
  .amplify-checkbox {
    align-items: flex-start;
  }

  .amplify-input,
  .amplify-select__wrapper {
    width: 95%;
  }

  // Landscape Mobile
  @media (min-width: 650px) {
    .checkbox-container {
      width: 47vw;
    }
  }

  .save-button {
    justify-content: center;
    left: 50%;
    right: 50%;

    .amplify-button {
      width: 90vw;
    }

    .button {
      margin-left: 0;
    }
  }
}
