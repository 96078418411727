@import "../../../assets/styles/variables.scss";

// Handles search/date inputs
.input {
  .amplify-input {
    background-color: white;
    height: 50px;
  }

  .amplify-textfield {
    margin: 2px 5px 6px;
  }

  // Handles the input labels
  .amplify-text {
    color: $dark-black;
    margin: 0;
  }
}

.input-with-no-label {
  .amplify-input {
    // margin-top: 24px;
  }
}

.mobile-input {
  .amplify-textfield {
    margin: 2px 5px;
  }
}

.time-input {
  width: 150px;
}

.date-input {
  display: flex;
  flex-wrap: wrap;
}

.input-with-no-label {
  display: flex;
  flex-wrap: wrap;
}

.save-date {
  margin: 14px 0 12px;
  button {
    height: 50px !important;
  }
}
