@import "../../../assets/styles/variables.scss";

.toll-import-page {
  .clickable-table-headers {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .amplify-button {
      width: 120px;
    }
  }

  .toll-import-modal {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: calc(50% + $navbar-width / 2);
    transform: translate(-50%, -50%);
    width: 80vw;
    height: 90vh;
    border-radius: $border-radius;
    background-color: $off-white;

    h1 {
      margin: 12px 0 12px 12px !important;
    }

    .modal-helper {
      p {
        position: absolute;
        top: 36px;
        left: 12px;
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 12px;
      color: $black;
      float: right;
      font-size: 28px;
      font-weight: bold;

      &:hover,
      &:focus {
        color: $blue;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
