@import "../../../assets/styles/variables.scss";

.navbar-container {
  position: fixed;
  width: $navbar-width;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: $black;
  z-index: 1000;
  box-shadow: 3px 0 2px -2px rgba(0, 0, 0, 0.4);

  .logo-container {
    margin-top: 18px;
    padding-left: 24px;
    padding-right: 24px;
    &:hover {
      cursor: pointer;
    }
  }

  .user-container {
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 20px 20px;
    font-size: 16px;
    color: $white;

    p {
      font-size: 16px;
      text-transform: capitalize;
    }

    .amplify-button {
      width: 82px;
      height: 44px;
      color: black;
      &:hover {
        color: $blue;
      }
    }

    .user-circle {
      text-align: center;
      height: 45px;
      width: 45px;
      background-color: $lime;
      border-radius: 50%;
      display: inline-block;
      font-weight: 500;
      font-size: 20px;
      padding: 6px 0 0 0;
      color: $black;
      margin: 15px 10px 0 -5px;
    }

    .user-info-container {
      background-color: $dark-black;
      width: 100%;
      position: absolute;
      z-index: -1;
      left: 0;
      height: 85px;
    }

    .user-info {
      display: inline;
      text-transform: capitalize;
      position: relative;
    }

    small {
      position: absolute;
      font-size: 12px;
      text-transform: capitalize;
      font-style: italic;
      top: 20px;
      left: 0;
    }

    .sign-out-container {
      padding-bottom: 8px;
      margin-left: -8px;
      background-color: $black;
      a {
        color: $white;
        .sign-out-icon {
          font-size: 20px;
          margin: -2px 10px;
          max-width: 20px;
        }
      }
    }
  }
}

.minimized-navbar-container {
  width: 70px;

  .logo-container {
    padding-left: 5px;
    padding-right: 25px;

    .title {
      width: 56px;
      margin-left: -10px;
      svg {
        transform: scale(0.6);
      }
    }
  }

  h1 {
    display: none;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    span {
      width: 36px;
    }

    .amplify-icon.amplify-expander__icon {
      display: none;
    }
  }

  .user-container {
    text-align: center;

    padding: 10px 0 20px 0;

    .user-info {
      display: none;
    }

    .user-circle {
      background-color: $dark-black;
      text-align: center;
      height: 45px;
      width: 45px;
      background-color: $lime;
      border-radius: 50%;
      display: inline-block;
      font-weight: 500;
      font-size: 20px;
      padding: 6px 0 0 0;
      color: $black;
      margin: 15px 0 0 -5px;
    }

    .sign-out-container {
      a {
        font-size: 0;
      }
    }
  }
}

.mobile-navbar-container {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  z-index: 1000;

  .logo-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 75px;
    margin-top: 0;
    padding-left: 6px;

    .x-container {
      width: 48px;
      cursor: pointer;
      font-size: 30px;
      font-weight: bold;
      color: $white;
      padding: 12px;
      margin-right: 3px;
    }
  }

  .nav-links {
    overflow: auto;
  }

  .user-container {
    justify-content: space-between;
    width: 100vw;
    background-color: $black;
  }

  @media (min-width: 650px) {
    .nav-links {
      height: 40vh;
      overflow: auto;
    }
  }
}

.hamburger-menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  display: flex;
  align-items: center;
  padding-left: 6px;
  z-index: 1000;
  width: 100%;
  height: $mobile-navbar-width;
  box-shadow: 0 1px 2px -2px gray;

  .hamburger-icon {
    display: flex;
  }

  .title {
    height: 75px;
    svg {
      margin-left: 12px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;

  h1 {
    font-size: 18px;
    margin: 0;
    width: 50%;
    margin-left: 4px;
    color: $white;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.nav-links {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: auto;
  overflow: auto;
  height: calc(100vh - 240px);
}

.user-container {
  position: absolute;
  bottom: 0;
  width: 100%;

  p {
    color: $white;
    font-size: 18px;
    text-align: center;
  }

  .button {
    margin: 0;
  }
}

.minimize-nav-container {
  position: relative;

  .minimize-nav-toggle {
    background-color: $white;
    color: $black;
    padding: 12px 7px;
    border-radius: 5px 0px 0px 5px;
    position: absolute;
    right: -25px;
    bottom: 9px;
    z-index: 200;
    width: 24px;
  }
}
