@import "../../../assets/styles/variables.scss";

.controls-container {
  .table-controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;

    .amplify-button {
      background-color: $off-white;
      border-color: $light-gray;
    }

    .amplify-loader {
      height: 48px;
      margin-left: 12px;
    }

    .amplify-pagination__item-current {
      background-color: $blue;
    }

    .disabled-csv-button {
      .csv-icon {
        fill: $light-gray;
      }
      pointer-events: none;
    }

    .csv-button {
      &:hover {
        cursor: pointer;
        .csv-icon {
          fill: $blue;
        }
      }
    }
  }

  .mobile-table-controls {
    margin-top: 60px;
    flex-direction: column-reverse;
    align-items: center;

    .amplify-pagination {
      width: 100%;
    }

    .csv-button {
      a {
        color: $blue;
        text-decoration: none;
      }
      p {
        margin-top: 2px;
      }
    }
  }

  .lookup-modal-container .lookup-modal {
    height: auto;
  }

  .mobile-page-container {
    height: auto;

    .search-form-container {
      height: calc(100vh - 140px) !important;
    }
  }

  .amplify-select.amplify-field-group__control {
    max-width: 300px !important;
  }
}

table {
  border-collapse: separate !important; /* Don't collapse */
  border-spacing: 0 !important;
}

.table-container {
  .table {
    height: calc(100vh - 180px);
  }

  .amplify-table__caption {
    position: sticky;
    top: 0;
    caption-side: top;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    background-color: $background-color;
  }

  .amplify-table__head {
    th {
      position: sticky;
      top: 0;
      background-color: $white;
      color: $dark-black;
      cursor: default;
      border: none;
      font-size: 16px;
      height: 84px;
      border-bottom: 3px solid #eaeaea;
    }
  }

  .amplify-table__row {
    height: 70px;

    &:nth-child(odd) {
      background-color: $shade-blue;
    }
    &:nth-child(even) {
      background-color: $white;
    }
  }

  .amplify-table__td {
    font-size: 13px;
    min-width: 180px;
    border: none;
    padding: 16px;
    height: 76px;
    color: $light-black;
  }

  .table:not(.matching-tables) {
    overflow: auto;
    padding-bottom: 12px;
    margin: 12px 20px;
  }

  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltipText {
    visibility: hidden;
    width: 140px;
    background-color: $black;
    color: $white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 900;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .tooltip .tooltipText::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $black transparent;
  }

  .tooltip:hover .tooltipText {
    visibility: visible;
  }
}

.cell-header {
  .arrow-icon-ascending {
    margin-left: 12px;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }
  .arrow-icon-descending {
    margin-left: 12px;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
  }

  p {
    margin: 0;
  }
}

.data-table-loader {
  .loader {
    background-color: transparent !important;
    position: absolute;
    height: 60px;
    width: 60px;
    // Center the loader
    top: 50%;
    left: calc(50% + $navbar-width / 2);
    transform: translate(-50%, -50%);
  }
}

.report-title {
  font-size: 23px;
  font-weight: 700;
  margin: 10px;
}

.no-results {
  p {
    margin-left: 10px;
  }
}
