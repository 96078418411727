.unauth-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  margin-top: 12px;

  h1 {
    font-size: 2.5rem;
    margin: 0;
  }

  p {
    margin: 0;
  }
}
