@import "../../../assets/styles/variables.scss";

.agency-page-container {
  width: -webkit-fill-available;

  .co-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 90;
  }

  .search-form-container {
    position: absolute;
    top: 50%;
    left: calc(50% + 120px);
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 94vh;
    width: 72vw;
    background-color: rgba(46, 49, 50, 0.95);
    z-index: 990;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0 12px;
  }

  .page-container {
    height: auto;
  }

  .extra-padding {
    padding-bottom: 5px;
  }

  .agency-edit-form-container {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: calc(50% + $navbar-width / 2);
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 75vh;
    border-radius: $border-radius;
    background-color: $off-white;

    .agency-edit-headers {
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        text-align: center;
      }

      .close {
        position: absolute;
        top: 0;
        right: 12px;
        color: $black;
        float: right;
        font-size: 28px;
        font-weight: bold;

        &:hover,
        &:focus {
          color: $blue;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    .agency-edit-body {
      height: calc(100% - 170px);
      overflow: auto;

      margin: 0 12px;

      .agency-edit-form {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .agency-inputs {
          display: flex;
          flex-wrap: wrap;
        }

        .amplify-input,
        .amplify-select__wrapper {
          width: 360px;
        }

        .checkbox-input {
          height: auto;
          padding-top: 6px;
          padding-left: 6px;
          padding-right: 250px;
        }
      }

      .edit-only-flows {
        margin-left: 5px;

        h2,
        p {
          margin: 0;
          padding: 0;
        }

        h2 {
          font-size: 16px;
          font-weight: bold;
        }

        p {
          font-size: 12px;
          font-style: italic;
          margin-bottom: 6px;
        }

        .button {
          margin-left: 0;
          .amplify-button {
            width: 180px;
          }
        }
      }
    }

    .agency-edit-footers {
      display: flex;
      position: absolute;
      padding: 12px 12px 12px 7px;
      bottom: 0;
      width: 100%;
      justify-content: flex-end;

      .button {
        .amplify-button {
          width: auto;
        }
      }
    }

    .historical-data-container {
      margin-left: 5px;
      margin-bottom: 12px;
      width: 100%;

      .amplify-textfield,
      .button {
        margin-left: 0 !important;
      }

      .table-container {
        .table {
          height: auto;
          margin: 0;
          margin-right: 12px;
        }
        .amplify-table__row {
          height: auto;
        }
        .amplify-table__head th p {
          font-weight: 500;
          font-style: normal;
          font-size: 14px;
          margin: 0;
        }
        .amplify-table__td {
          white-space: break-spaces;
          height: auto;
        }
      }

      h2 {
        font-size: 16px;
        margin: 12px 0;
        color: $dark-black;
        font-weight: 500;
      }
    }
  }

  .mobile-agency-management-container {
    .agency-edit-form-container {
      width: 100vw;
      height: calc(100vh - $mobile-navbar-width);
      top: $mobile-navbar-width;
      left: 0;
      transform: none;
      border-radius: 0;
    }
  }
}

.autocomplete-container {
  margin-right: 5px;

  .label {
    margin: 5px 0;
  }

  .amplify-input {
    height: 50px !important;
    padding: 0 0 0 104px !important;
    width: 27vw;
  }

  .autocomplete {
    width: 100%;
    height: 49px;
  }

  .autocomplete-label {
    position: absolute;
    background-color: $blue;
    color: $white;
    padding: 7px 15px;
    border-radius: 6px;
    margin: 6px 0 0 5px;
    width: 90px;
    text-align: center;
    text-transform: capitalize;
  }
}
