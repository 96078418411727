@import "../../../assets/styles/variables.scss";

.toll-matching-container {
  .amplify-select.amplify-field-group__control {
    max-width: 240px;
  }
}

.matching-table-container {
  display: flex;
  overflow: auto;
  height: calc(100vh - 186px);
  margin: 12px;

  .amplify-table__caption {
    caption-side: top;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
  }

  .table-container {
    margin-right: 12px;
  }
}
