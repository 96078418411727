@import "../../../assets/styles/variables.scss";

.mapboxgl-popup-content,
.maplibregl-popup-content {
  min-height: 240px;
  height: 100%;
}

.geofence-modal {
  margin: 6px;
  display: flex;
  flex-direction: column;
  white-space: pre;
  height: 74vh;
  width: 420px;

  .modal-headers-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    position: relative;

    .modal-headers {
      text-align: center;
      color: $black;
      height: auto;

      .position-relative {
        position: static !important;

        .icons-container {
          display: flex;
          align-items: center;
          position: absolute;
          top: 4px;
          right: 4px;
        }
      }

      .x-container {
        cursor: pointer;
        font-size: 18px;
        width: 14px;
        margin-left: 4px;
        &:hover {
          color: $blue;
        }
      }

      .amplify-heading {
        width: 80%;
        word-wrap: break-word;
        font-size: 22px;
        font-weight: 500;
      }

      .lock-container {
        font-size: 24px;
        .lock-icon {
          &:hover {
            fill: $blue;
            cursor: pointer;
          }
        }
      }
    }

    h1 {
      margin: 4px 0 3px 0px;
      color: $blue;
      font-weight: 600;
      font-family: $headers-font;
      word-wrap: break-word;
      text-align: left;
      width: calc(100% - 48px);
      white-space: normal;
      line-height: 1.1;
    }

    h2 {
      margin: 0;
      color: $blue;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .geofence-form {
    font-family: $text-font;
    border: 1px solid $gray;
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 6px;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    padding: 15px 6px 0 0;
    justify-content: center;

    .button {
      width: 100%;

      .icon {
        position: relative;

        svg {
          position: absolute;
          bottom: 25px;
          left: 90px;
          font-size: 18px;
          color: $blue;
          height: 130%;
        }
      }
    }

    .amplify-button {
      width: 100%;
      margin-bottom: 5px;
      padding-top: 30px;
      height: 100%;
    }
  }
  .create-button-container {
    display: flex;
    flex-direction: row;
    padding: 15px 6px 0 0;
    justify-content: center;
    background-color: $white;
    border-top: 1px solid $gray;
    margin-top: 10px;

    .button {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }

    .amplify-button {
      width: 100%;
      padding: 10px;
      height: 100%;
    }
  }
}

.creating-geofence-modal {
  .property-inputs-container {
    .location-details,
    .agency-details,
    .location-type,
    .location-actions,
    .location-extras {
      flex-direction: column;
      .amplify-textfield,
      .amplify-selectfield {
        width: 320px !important;
      }
    }
  }
}

.read-only-geofence-modal {
  background-color: $white;
  padding: 10px 10px;
  color: $black;
  word-wrap: break-word;
  font-size: 16px;
  border-radius: 5px;
  height: 100%;

  .header-text {
    font-size: 16px;
    font-weight: 500;
  }
}

.edit-geofence-modal {
  margin: 12px;

  .container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 12px;
  }

  .property-inputs-container {
    height: calc(95vh - 224px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .location-details,
    .agency-details,
    .location-type,
    .location-actions,
    .location-extras {
      display: flex;
      max-width: 41vw;
    }

    .location-details,
    .location-type,
    .location-actions,
    .location-extras {
      .amplify-textfield,
      .amplify-selectfield {
        width: 220px;
      }
    }

    .agency-details {
      .amplify-selectfield {
        width: 680px;
      }
    }

    .location-extras {
      display: flex;
      flex-direction: column;

      .location-status {
        display: flex;
        align-items: center;
        .amplify-checkbox {
          margin-left: 6px;
          margin-top: 24px;
        }
      }

      .notes {
        .amplify-textarea {
          width: 680px !important;
          height: 140px !important;
        }
      }
    }

    .property-inputs-block {
      display: flex;
      flex-wrap: wrap;
    }

    .actions-container {
      display: flex;
    }

    .actions-container-creation {
      display: flex;
      flex-direction: column;
    }

    .amplify-text {
      color: $black;
    }
  }

  .verified-checkbox {
    margin-bottom: 12px;
    p {
      margin: 12px 0 6px;
      color: $dark-black;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .historical-data-container {
    margin-left: 5px;

    h2 {
      font-size: 16px;
      margin: 1px 0;
      color: $black;
      font-weight: 500;
      font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    }

    .table-container {
      .table {
        height: auto;
        margin: 0;
        width: 60vw;
      }
      .amplify-table__row {
        height: auto;
      }
      .amplify-table__head th p {
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        margin: 0;
      }
      .amplify-table__td {
        white-space: break-spaces;
        height: auto;
      }
    }
  }

  .button-container {
    display: flex;
    position: absolute;
    right: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 60px;
    background-color: $off-white;

    .amplify-button {
      position: absolute;
      top: 10px;
      right: 6px !important;
      width: auto !important;
      padding-top: 8px;
      height: 50px;
    }
  }
}

.modal-headers-container .edit-locations-container {
  p {
    font-size: 16px;
  }

  .amplify-textareafield {
    .amplify-text {
      color: $black;
    }

    .amplify-textarea {
      width: 77%;
    }
  }

  .schedule-form-container {
    .button-container {
      flex-direction: row;

      .amplify-button {
        width: 120px !important;
      }
    }
  }

  .save-button {
    button .amplify-button {
      width: 120px !important;
    }
  }
}

.mobile-geofence-modal {
  margin: 6px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $mobile-full-height;
  padding-bottom: 36px;

  .modal-headers-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;

    .modal-headers {
      display: flex;
      justify-content: center;
      align-items: center;

      .lock-container {
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }

    h2 {
      margin: 0;
      color: $blue;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
    }
  }

  .read-only-geofence-modal {
    width: 98vw;

    .header-text {
      text-align: center;
    }
  }

  .edit-geofence-modal {
    width: 95vw;
    margin: 0;

    .property-inputs-container {
      flex-direction: column;
      height: calc(95vh - 240px);

      .location-details,
      .agency-details,
      .location-type,
      .location-actions,
      .location-extras {
        flex-direction: column;
        .amplify-textfield,
        .amplify-selectfield {
          width: 90%;
        }
      }

      .location-details,
      .location-type,
      .location-actions,
      .location-extras {
        .amplify-textfield,
        .amplify-selectfield {
          width: 80vw !important;
        }
      }

      .location-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 6px;
        .amplify-checkbox {
          margin: 8px 0;
        }
      }

      .agency-details {
        .amplify-selectfield {
          width: 80vw !important;
        }
      }

      .notes {
        .amplify-textarea {
          width: 80vw !important;
          height: 100px !important;
          margin-bottom: 40px;
        }
      }
    }

    .notes-textarea .text-area-input .amplify-textarea {
      width: 80vw;
    }

    .event-container {
      .event-inputs {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      width: 100vw;
      background-color: $off-white;
      bottom: 0;
      left: 0;
      height: 60px;
      margin-top: 40px;

      .amplify-button {
        width: 100%;
        margin: 4px 0;
      }
    }

    .schedule-inputs,
    .charges-inputs {
      justify-content: center;
    }
  }

  .amplify-heading {
    font-size: 24px;
    margin-top: 12px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    padding-right: 4px;
    position: absolute;
    bottom: 48px;
    width: 100%;

    .amplify-button {
      width: 100%;
      margin-bottom: 4px;
    }
  }

  .close-button {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    font-size: 30px !important;
    color: red;
    font-size: 500;
  }

  .x-container-mobile {
    position: absolute;
    left: 20px;
    font-size: 20px;
    cursor: pointer;
  }

  .rates-edit-container {
    height: calc(95vh - 240px) !important;
  }
}

.icons-container {
  position: relative;
}

.properties-card {
  p {
    font-size: 15px;
    margin: 7px 0;
  }
}

.editButton {
  position: relative;
  span {
    position: absolute;
    right: 0;
    bottom: -10px;
    font-size: 20px;
    color: $blue;
    cursor: pointer;
  }
  svg {
    position: absolute;
    right: 0;
    width: 18px;
    bottom: 0;
    color: $blue;
    cursor: pointer;
  }
}

.position-relative {
  position: relative;
}
