@import "../../../assets/styles/variables.scss";

.custom-autocomplete {
  margin-top: 10px;
  .position-relative {
    position: relative;
  }
  .amplify-field-group__icon {
    display: none !important;
  }

  .custom-autocomplete-title {
    margin: 0;
    padding-bottom: 6px;
  }

  .autocomplete-label {
    position: absolute;
    background-color: $blue;
    color: $white;
    padding: 7px 15px;
    border-radius: 6px;
    margin: 6px 0 0 5px;
    width: 90px;
    text-align: center;
    text-transform: capitalize;
  }

  .autocomplete-dropdown {
    position: absolute;
    background-color: $blue;
    color: $white;
    padding: 7px 15px;
    border-radius: 6px;
    margin: 6px 0 0 5px;
    min-width: 90px;
    text-align: center;
    text-transform: capitalize;
    z-index: 99;
    border-right: 4px solid $blue; // Makes the chevron in the select move over to the left some

    option {
      background-color: $white;
      color: $black;
      text-align: left;
    }
  }

  .reports-autocomplete {
    width: 100%;
    height: 49px;
  }

  .amplify-input {
    height: 50px !important;
    padding: 0 0 0 104px !important;
    width: 27vw;
  }

  .vehicle-search {
    position: absolute;
    top: 2px;
    right: 5px;
    bottom: 0;
    background-color: transparent;
    border: none;
  }
  .icon {
    font-size: 18px;
    max-width: 20px;
    width: 20px;
    height: 20px;
  }
}
