@import "../../assets/styles/variables.scss";

.amplify-expander__item {
  border-radius: 0 !important;
}

.parent-route {
  .parent-mobile-route {
    height: 60px !important;
  }

  .parent-route-title {
    padding-left: 20px;
    height: 48px;
    display: flex;
    align-items: center;

    h1 {
      color: $white;
      margin-left: 12px;
      font-size: 17px;
      font-weight: 500;
      &:hover {
        color: $light-gray;
      }
    }
  }

  .child-nav-text {
    padding-left: 64px;
  }

  .minimized-nav-text {
    display: flex;
    justify-content: center;
    padding-left: 4px;
    p {
      font-size: 14px !important;
      margin: 0 !important;
    }
  }

  .amplify-expander {
    background-color: transparent;
    box-shadow: none;
    color: $white;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
  }

  .amplify-expander__trigger {
    padding-left: 0;
    padding-right: 12px;

    svg {
      &:hover {
        color: $light-gray;
      }
    }
    &:hover {
      background-color: transparent;
    }
  }

  .amplify-expander__header,
  .amplify-expander__item:focus-within {
    box-shadow: none;
  }

  .amplify-expander__content__text,
  .amplify-expander__content {
    padding: 0;
  }
}

.route {
  margin-bottom: 4px;
  cursor: pointer;

  .active {
    margin: 0 15px;
    border-left: 3px solid $white !important;

    p {
      background-color: $blue;
      border-radius: 5px;
      padding: 10px 10px !important;
      padding-right: 0 !important;
      width: 100%;
    }
  }

  span:not(.amplify-expander__icon) {
    height: 48px;
    display: flex;
    align-items: center;
    border-left: 3px solid $dark-black;
    padding: 10px 0 10px 0;
    margin-left: 50px;

    p {
      color: $white;
      margin-left: 12px;
      font-weight: 500;
      font-size: 16px;
      &:hover {
        color: $light-gray;
      }
    }
  }
}

.mobile-route {
  span:not(.amplify-expander__icon) {
    height: 60px;
  }
}

.route span:not(.amplify-expander__icon) {
  p {
    padding: 10px 15px 10px 10px;
    margin-left: 5px;
  }
}

.parent-route {
  .map-route {
    span:not(.amplify-expander__icon) {
      border-left: none;
      margin-left: 20px;
      h1 {
        color: $white;
        margin: 2px 0 0 12px;
        font-weight: 500;
        font-size: 17px;
        height: 100%;
        &:hover {
          color: $light-gray;
        }
      }
    }

    .active {
      margin-left: 10px !important;
      border-left: none !important;
      background-color: $blue;
      border-radius: 5px;
      padding: 10px 10px !important;

      h1 {
        color: $white;
        margin: 12px 0 0 12px;
        font-weight: 500;
        font-size: 18px;
        height: 100%;
        &:hover {
          color: $light-gray;
        }
      }
    }
  }
}

.minimized-parent-route {
  .map-route {
    span:not(.amplify-expander__icon) {
      border-left: none;
      margin-left: 14px !important;
    }

    .active {
      margin-left: -5px;
      border-left: none !important;
      background-color: $blue;
      border-radius: 5px;
      width: 48px;
      svg {
        margin-left: 12px;
      }
    }
  }
}
