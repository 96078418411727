@import "../../../assets/styles/variables.scss";

.clickable-table-container {
  display: flex;
  flex-direction: column;

  .clickable-table-with-button {
    .clickable-table-table {
      height: calc(83vh - 70px) !important;
      padding: 0 12px;
      overflow: hidden;
    }

    .label-with-button {
      padding: 10px 12px;
      .csv-button {
        display: inline;
        margin: 0 15px 0 0;
      }
      h1 {
        display: inline;
        font-size: 23px !important;
      }
    }
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltipText {
    visibility: hidden;
    width: 140px;
    background-color: $black;
    color: $white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 7px;
    position: absolute;
    z-index: 900;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-weight: 400;
    font-size: 14px;
  }

  .tooltip .tooltipText::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $black transparent;
  }

  .tooltip:hover .tooltipText {
    visibility: visible;
  }

  .clickable-table-headers {
    h1 {
      font-size: 23px;
      font-weight: 700;
      margin: 10px;
    }
  }

  .headers-with-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: $white;
    height: 92px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px 20px 13px;

    .label-with-button {
      padding: 0;
    }

    .autocomplete-bar {
      width: 100%;
      position: relative;
      max-width: 517px;
      float: right;

      .autoCompleteLabel {
        position: absolute;
        background-color: #2746f2;
        color: #ffffff;
        padding: 7px 15px;
        border-radius: 6px;
        margin: 6px 0 0 5px;
        min-width: 90px;
        text-align: center;
        text-transform: capitalize;
        z-index: 100;
        right: 420px;
      }

      .autocomplete-dropdown {
        position: absolute;
        background-color: $blue;
        color: $white;
        padding: 7px 15px;
        border-radius: 6px;
        margin: 6px 0 0 5px;
        min-width: 90px;
        text-align: center;
        text-transform: capitalize;
        z-index: 99;
        border-right: 4px solid $blue;
        right: 420px;

        option {
          background-color: $white;
          color: $black;
          text-align: left;
        }
      }

      .amplify-field-group--has-inner-start .amplify-input {
        height: 50px;
        padding: 0 0 0 106px !important;
        border-radius: 6px;
      }

      .vehicle-search {
        position: absolute;
        right: 5px;
        bottom: 10px;
        background-color: $white;
        border: none;
        cursor: pointer;
      }

      .icon {
        font-size: 18px;
        max-width: 20px;
        width: 20px;
        height: 20px;
      }
    }

    .label-with-button {
      display: flex;
      align-items: center;
      .csv-button {
        margin-right: 8px;
        transform: scale(0.9);
        margin-left: -4px;
        &:hover {
          cursor: pointer;
          .csv-icon {
            fill: $blue;
          }
        }
      }

      .amplify-field-group__inner-end {
        display: flex !important;

        .amplify-icon {
          color: $blue !important;
        }

        .amplify-button {
          &:hover,
          &:focus {
            background-color: transparent !important;
          }
        }
      }
    }

    .reset-button {
      button.amplify-button.amplify-field-group__control {
        width: 120px;
        height: 50px;
        &:hover {
          opacity: 0.9;
        }
      }
    }

    .amplify-button.amplify-field-group__control {
      height: 50px;
      min-width: 112px;
    }
  }

  .cell-header {
    // .arrow-icon-ascending {
    //   margin-left: 12px;
    //   position: absolute;
    //   right: 12px;
    //   top: 50%;
    //   transform: translateY(-50%) rotate(180deg);
    // }
    // .arrow-icon-descending {
    //   margin-left: 12px;
    //   position: absolute;
    //   left: 35px;
    //   right: 0;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }

    p {
      margin: 0 !important;
      font-size: 18px;
    }
  }

  .clickable-table-table {
    overflow: auto;
    padding-bottom: 18px;
  }

  table {
    border-collapse: separate !important; /* Don't collapse */
    border-spacing: 0 !important;
  }

  .amplify-table__head {
    th {
      top: 0;
      position: sticky;
      background-color: $white;
      color: $dark-black;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 3px solid #eaeaea;
      border: none;
      font-size: 16px;
    }
    th {
      border-bottom: 3px solid #eaeaea;
    }
  }

  .amplify-table__row {
    height: 70px;

    &:nth-child(odd) {
      background-color: $shade-blue !important;
    }
    &:nth-child(even) {
      background-color: $white !important;
    }
  }

  .amplify-table__th {
    cursor: pointer;
    border-color: $dark-black;
    height: 80px;
  }

  .amplify-table__td {
    font-size: 12px;
    min-width: 200px;
    cursor: pointer;
    height: 70px;
    white-space: pre;
  }

  .amplify-table[data-highlightonhover="true"]
    .amplify-table__row:not(.amplify-table__head *):hover {
    background-color: $blue !important;
    td {
      color: white;
    }
  }

  .amplify-table__row:not(.amplify-table__head *):nth-child(odd) {
    background-color: $shade-blue;
  }

  .amplify-table__row:not(.amplify-table__head *):nth-child(even) {
    background-color: $white;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 12px;
    right: 12px;

    .upload-button {
      margin-right: 12px;
      button.amplify-button.amplify-field-group__control {
        width: 100%;
        &:hover {
          opacity: 0.9;
        }
      }
    }

    .action-button {
      margin-right: 12px;
      button.amplify-button.amplify-field-group__control {
        background-color: $blue;
        color: white;
        width: 100%;
        border: none;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

.mobile-clickable-table-container {
  height: auto;

  .clickable-table-with-button {
    .clickable-table-table {
      height: calc(100vh - 214px) !important;
    }

    .button-container {
      width: 100%;
      background-color: $off-white;
      height: 60px;
      bottom: 0;
      right: 0;
      padding-top: 6px;
      justify-content: center;

      .action-button {
        button.amplify-button.amplify-field-group__control {
          width: 90vw;
        }
      }
    }
  }

  .headers-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .clickable-table-headers {
    position: sticky;

    h1 {
      font-size: 24px;
      padding-right: 0;
    }
  }
}

.no-data-screen {
  padding-left: 12px;
}

.amplify-field-group__inner-start {
  display: none !important;
}
