@import "../../../assets/styles/variables.scss";

.locations-admin-container {
  width: -webkit-fill-available;

  .clickable-table-container {
    .headers-with-button {
      .label-with-button {
        padding: 0;
      }
    }

    .clickable-table-table {
      height: calc(83vh - 70px) !important;
      padding: 0 12px;
    }

    .label-with-button {
      padding: 10px 12px;
      .csv-button {
        display: inline;
      }
      h1 {
        display: inline;
        margin: 0 0 0 15px;
        font-size: 23px;
      }
    }

    .amplify-button {
      min-width: 120px;
      height: 50px;
    }
  }

  .search-input {
    position: absolute;
    right: 6px;
    top: 8px;
  }

  .search-form-container {
    position: absolute;
    top: 50%;
    left: calc(50% + 120px);
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 70vh;
    width: 60vw;
    background-color: rgba(46, 49, 50, 0.95);
    z-index: 990;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 0 12px;

    .amplify-checkbox__button {
      border-radius: 4px;
    }
  }
}

.edit-locations-container {
  z-index: 999;
  background-color: $off-white;
  height: 80vh;
  width: 74vw;
  position: fixed;
  top: 50%;
  left: calc(50% + 120px);
  transform: translateY(-50%) translateX(-50%);
  border-radius: 4px;
  box-shadow: $box-shadow;
  overflow: auto;

  .edit-form-headers-container {
    display: flex;

    .x-container {
      position: absolute;
      cursor: pointer;
      font-size: 30px;
      right: 12px;
      top: 10px;
      color: $dark-black;
      &:hover {
        color: $blue;
      }
    }

    .edit-form-headers {
      display: flex;
      flex-direction: column;
      margin: 12px;

      .edit-form-headers-label {
        display: flex;
        align-items: center;
        .amplify-checkbox {
          margin-right: 8px;
        }
      }

      .map-links {
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;
      }

      h1 {
        margin: 0;
        padding: 0;
        margin-right: 8px;
        font-size: 28px;
        line-height: 42px;
      }
      h2 {
        margin: 0;
        color: $blue;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .edit-forms {
    .tabs-container {
      margin-top: -6px;
    }
  }

  .amplify-text {
    color: $dark-black;
    font-weight: 500;
  }

  .property-inputs-container {
    display: flex;
    height: calc(75vh - 218px);
    overflow: auto;
  }

  .rates-edit-container {
    margin-top: 6px;
    height: calc(75vh - 218px);
    overflow: auto;

    .button-container {
      display: flex;
      position: absolute;
      bottom: 0 !important;
      right: 0 !important;
      width: 100%;
      height: 60px;
      background-color: $off-white;

      .amplify-button {
        position: absolute;
        top: 6px;
        right: 6px !important;
        width: auto !important;
      }
    }

    .extra-margin {
      .save-date {
        margin-top: 18px;
      }
    }
  }
}

.mobile-locations-admin-container {
  .search-input {
    position: relative;
    top: 0;
    left: 0;

    .amplify-input {
      width: 100%;
    }
  }

  .clickable-table-container {
    height: calc(100dvh - 80px);
    overflow: auto;
  }

  .edit-geofence-modal {
    width: 95vw;
    margin: 0;

    .property-inputs-container {
      flex-direction: column;
      height: calc(95vh - 260px);

      .location-details,
      .agency-details,
      .location-type,
      .location-actions,
      .location-extras {
        flex-direction: column;
        .amplify-textfield,
        .amplify-selectfield {
          width: 90%;
        }
      }

      .location-details,
      .location-type,
      .location-actions,
      .location-extras {
        .amplify-textfield,
        .amplify-selectfield {
          width: 80vw !important;
        }
      }

      .location-status {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 6px;
        .amplify-checkbox {
          margin: 8px 0;
        }
      }

      .agency-details {
        .amplify-selectfield {
          width: 80vw !important;
        }
      }

      .notes {
        .amplify-textarea {
          width: 80vw !important;
          height: 100px !important;
          margin-bottom: 40px;
        }
      }
    }

    .notes-textarea .text-area-input .amplify-textarea {
      width: 100%;
      margin-bottom: 40px;
    }

    .event-container {
      .event-inputs {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      width: 100vw;
      background-color: $off-white;

      height: 60px;
      margin-top: 40px;
      margin-left: 0;

      .amplify-button {
        width: 90vw !important;
        margin: 4px 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .schedule-inputs,
    .charges-inputs {
      justify-content: center;
    }
  }
}

.mobile-edit-locations-container {
  border-radius: 0;
  height: calc(100dvh - 74px);
  padding-bottom: 36px;
  left: 0;
  transform: none;
  top: 75px;
  width: 100vw;
  box-shadow: none;

  .rates-edit-container {
    height: calc(95vh - 290px);
  }
}

.locations-modal {
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 35px 20px 20px 20px;
  left: 0;
  top: 0;
  overflow: auto;
  .content {
    background-color: #fff;
    margin: auto;
    padding: 20px 20px 50px 20px;
    border-radius: $border-radius;
    width: 25%;
  }
  .bt-upload {
    background-color: #fff;
    color: #3956ff;
  }
  .bt-close {
    float: right;
    background-color: #3956ff;
    color: #fff;
  }
  .close {
    margin: -25px -5px 0 0;
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .border-0 {
    border: 0;
  }
  .text-center {
    text-align: center;
  }
  .fw-bold {
    font-weight: bold;
  }
  .text-primary {
    color: #3956ff;
  }
}

.locations-button {
  float: right;
  margin-right: 15px;
  background-color: white;
  color: #3956ff;
  font-size: 16px;
  height: 44px;
  width: auto;
  padding: 6px;
}

.button-container-locations {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 12px;
  right: 12px;

  .add-button {
    float: right;
    margin-right: 12px;
    button.amplify-button.amplify-field-group__control {
      background-color: $blue;
      color: white;
      width: 100%;
      border: none;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.px-1 {
  margin-right: 5px;
}

.co-modal-container {
  .advanced-search-container {
    height: calc(90vh - 145px);
    overflow: auto;

    h2 {
      margin: 10px;
      font-size: 18px;
      font-weight: 600;
      color: $light-black;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .inputs-container {
      display: flex;
      padding: 0px 0 10px 30px;

      .custom-autocomplete {
        margin-top: 4px;
      }

      .amplify-input {
        height: 50px;
      }

      .input .amplify-textfield,
      .amplify-selectfield {
        margin-left: 0;
      }
    }

    .advanced-search-submit {
      width: 100%;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      padding: 12px;
      background-color: $white;
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

button.amplify-button.amplify-field-group__control.add-new-rate {
  top: -10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
