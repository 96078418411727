@import "../../../assets/styles/variables.scss";

.checkbox-form-container {
  margin: 0 5px;

  p {
    color: $black;
    font-size: 16px;
    margin: 12px 0 6px;
  }

  .amplify-checkbox {
    margin: 4px 0;
  }
}

.mobile-checkbox-form-container {
  margin-left: 5px;
}
