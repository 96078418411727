@import "../../../assets/styles/variables.scss";

.messages-page-container {
  .clickable-table-container {
    .label-with-button {
      padding: 0 12px;

      h1 {
        font-size: 23px;
      }
    }
    .clickable-table-table {
      height: calc(83vh - 70px) !important;
      padding: 0 12px;
    }
  }
  .messages-edit-form-container {
    z-index: 100;
    background-color: $off-white;
    height: 82vh;
    width: 62vw;
    position: fixed;
    top: 50%;
    left: calc(50% + 105px);
    transform: translateY(-50%) translateX(-50%);
    border-radius: 4px;
    padding-left: 6px;
    padding-right: 6px;
    box-shadow: $box-shadow;
    z-index: 999;
    overflow: auto;

    .edit-form-headers {
      display: flex;
      justify-content: center;
      align-items: center;

      .x-container {
        position: absolute;
        cursor: pointer;
        font-size: 30px;
        font-weight: bold;
        right: 25px;
        top: 0;
        color: $dark-black;
        border: none;
        background-color: transparent;
        &:hover {
          color: $blue;
        }
      }

      h1 {
        margin-bottom: 12px;
        padding: 0;
      }
    }

    .edit-form-inputs {
      display: flex;
      flex-direction: column;
      margin: 0;
      overflow: auto;
      height: calc(85vh - 170px);

      .amplify-flex.amplify-field.amplify-textareafield {
        margin: 2px 5px 6px;
      }

      .amplify-textarea {
        background-color: white;
      }

      .single-button-form-container {
        display: block;
      }

      .buttons-form-container {
        display: flex;
        .amplify-textfield,
        .amplify-selectfield {
          width: calc(61.3vw / 2 - 16px);
        }
      }

      .button-toggle-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .amplify-button {
          margin: 6px 5px -12px;
          width: 160px;
        }
      }
    }

    .button-container {
      display: flex;
      position: absolute;
      bottom: 0 !important;
      right: 0 !important;
      width: 100%;
      height: 60px;
      background-color: $off-white;

      .amplify-button {
        position: absolute;
        top: 6px;
        right: 6px !important;
        width: 160px !important;
      }
    }
  }
}
