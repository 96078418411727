// colors
$black: #2e3132;
$white: #ffffff;
$blue: #2746f2;
$light-blue: #667dff;
$shade-blue: #ecf3ff;
$header-blue: #002bcf;
$lime: #c8ea3b;
$green: #a9c23f;
$teal: #1d8296;
$navy: #464e7e;
$light-black: #5c5f60;
$dark-black: #1c1d1d;
$gray: #c5c6c7;
$light-gray: #b5b0aa;
$off-white: #e4e5e7;
$amplify-input-border: rgb(137, 148, 159);
$background-color: #eaeaea;
$flyout-background-color: #f9f9f9;
$red: #ffdad6;

//Font family
$text-font: "Inter", sans-serif;
$headers-font: "Montserrat", sans-serif;

// screen size and breakpoints
$mobile-full-height: calc(90vh - 135px);
$mobileLandscape: "only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)";

// box shadow
$box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);

// navbar width
$navbar-width: 240px;
$mobile-navbar-width: 75px;

// common border radius
$border-radius: 6px;
